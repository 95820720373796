<template>
  <svg class="icon">
    <use :xlink:href="'#' + themeName" />
  </svg>
</template>

<script>
let importAll = requireContext => requireContext.keys().forEach(requireContext);
try {
  importAll(require.context("../assets/icons", true, /\.svg$/));
} catch (error) {
  console.log(error);
}
export default {
  props: ["name"],
  name: "Icon",
  computed: {
    themeName() {
      console.log(document.body.className);
      return document.body.className.split(" ")[0] + "-" + this.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
